import React from 'react';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import Post from './Post';

const Posts = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledContent = styled(Content)`
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 80px;
  padding: 0 20px;

  ${({ theme }) => theme.mq.lg} {
    max-width: 1000px;
    padding: 0 60px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto, 1fr);
    gap: 45px 30px;
  }

  ${({ theme }) => theme.mq.xl} {
    max-width: 1300px;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 45px;
  }

  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
  }
`;

const BlogPosts = ({ posts }) => {
  return (
    <Posts>
      <StyledContent>
        {posts.map(post => (
          <Post
            key={post.id}
            img={post.img}
            title={post.title}
            description={post.description}
            author={post.author}
            date={post.date}
            link={post.link}
          />
        ))}
      </StyledContent>
    </Posts>
  );
};

export default BlogPosts;
