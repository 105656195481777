import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const CardLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  ${({ theme }) => theme.mq.lg} {
    height: 100%;
    justify-self: center;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  min-height: 385px;
  margin-bottom: 40px;
  box-shadow: rgb(112 112 112 / 20%) 0px 5px 15px;

  &:hover {
    box-shadow: rgb(112 112 112 / 32.5%) 0px 5px 15px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.mq.xl} {
    min-height: 385px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 25px 15px 50px;

  ${({ theme }) => theme.mq.xl} {
    padding: 35px 25px 60px;
  }
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.bold};
  text-align: center;
`;

const Info = styled.p`
  position: relative;
  margin: 30px 0 25px;
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.light};
  text-align: center;
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 65px;
    background: rgb(0, 0, 0);
    margin: 15px auto 20px;
  }
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.light};
  text-align: center;
`;

const Post = ({ img, link, title, description, author, date, even }) => {
  return (
    <CardLink to={link} even={even}>
      <Card>
        <Content>
          <Title>{title}</Title>
          <Info>{author}</Info>
          <Description>{description}</Description>
        </Content>
      </Card>
    </CardLink>
  );
};

export default Post;
